import React from "react"
import Layout from "../components/Layout"

import ReactPlayer from "react-player"

const greta = () => {
  return (
    <Layout>
      <section style={{ position: "relative" }}></section>
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          backgroundColor: "#1d242a",
          padding: "40px",
        }}
      >
        <div
          style={{
            backgroundColor: "black",
            width: "50%",
            height: "700px",
            justifyContent: "center",
          }}
        >
          <ReactPlayer
            url="https://youtu.be/DRYOHUvPGlI"
            playing={true}
            loop={true}
            width="100%"
            height="100%"
            light="/thehub.jpeg"
          />
        </div>
        <div
          style={{
            backgroundColor: "#1d242a",
            width: "50%",
            height: "700px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              paddingTop: "50px",
              color: "white",
              fontFamily: "Inconsolata",
              fontSize: "25px",
              margin: "40px",
              textAlign: "center",
            }}
          >
            <h2>The Hub: An Interdisciplinary Project. Group Channel 5</h2> For
            the interdisciplinary project The Hub, an audio installation was
            created using Engine Processing. In the installation, the small
            pixel character Iggy can be guided through the mines by sound
            recognition. The obstacles on the way of the figure can be removed
            by the player through sounds (clapping, singing or music). The
            figure moves through several computer screens.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default greta
